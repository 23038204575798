import React from "react";

import GenericSite from "../components/GenericSite";
import a11yDeclarationS1 from "../snippets/a11y-declaration-s1.html";
import a11yDeclarationS2 from "../snippets/a11y-declaration-s2.html";
import a11yDeclarationS3 from "../snippets/a11y-declaration-s3.html";

const A11yDeclaration = () => {
  return (
    <GenericSite
      title="Deklaracja dostępności"
      sections={[
        {
          id: "1",
          title: "Deklaracja dostępności serwisu schronisko.tychy.pl",
          text: a11yDeclarationS1,
        },
        {
          id: "2",
          title: "Informacje zwrotne i dane kontaktowe",
          text: a11yDeclarationS2,
        },
        {
          id: "3",
          title: "Dostępność architektoniczna",
          text: a11yDeclarationS3,
        },
      ]}
    />
  );
};

export default A11yDeclaration;
